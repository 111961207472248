/* eslint-disable i18next/no-literal-string */
import styled from '@emotion/styled';


export const colors = {
    black: '#232326',
    darkGrey: '#515151',
    mediumGrey: '#9F9F9F',
    grey: '#D6D6D6',
    shadowGrey: '#DEDFE9',
    lightGrey: '#FBFBFB',
    white: '#FFFFFF',
    ghostWhite: '#F0F0FF',
    purple: '#AAA6D5',
    darkPurple: '#4B4ED6',
    darkerPurple: '#262897',
    red: '#EE5D5D',
    transparent: 'rgba(255, 255, 255, 0)',
};

export const fonts = {
    sansSerif: "'Montserrat', sans-serif",
    monospace: "'Space Mono', monospace",
    monospaceRegular: "'Space Mono Regular', monospace",
};

export const fontWeights = {
    semiLight: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
};

// Same as bootstrap
export const breakpoints = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
};

export const maxWidth = 1400;

export const ContentContainer = styled.div`
    max-width: ${maxWidth}px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;

    @media (min-width: ${breakpoints.md}) {
        padding-left: 0;
        padding-right: 0;
    }
`;
