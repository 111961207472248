import {useState, useEffect} from 'react';


/**
 * Hook to access the local storage.
 *
 * @param {string} storageKey - the key to add to the storage
 * @param {*} defaultValue - the default value for the property to store
 * @returns {[*, function]} the stored value and the method to set new values in the storage
 */
export default (storageKey, defaultValue = null) => {
    if (typeof window === 'undefined') {
        return [defaultValue, () => {}];
    }

    const storage = window.localStorage;
    const [value, setValue] = useState(storage.getItem(storageKey) || JSON.stringify(defaultValue));

    useEffect(() => storage.setItem(storageKey, value), [storage, storageKey, value]);

    return [JSON.parse(value), setValue];
};
