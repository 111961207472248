import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {ContrastContext} from '../../helpers/context';
import Chatbot from './Chatbot';
import CookiesBanner from './CookiesBanner';
import Header from './Header';
import Footer from './Footer';
import {PagesTransition} from './Transitions';

import '../../styles/index.scss';

/**
 * Display the page's layout
 *
 * @param {node} children - content of the page
 * @param {Object} location - current location of the document
 */

const Layout = ({location, children}) => {
    // Each page element controls whether the Header should use the contrast (light) theme or not. We pass the necessary
    // update method by context to the page, so that it can set the proper theme for the header.
    const [contrast, setContrast] = useState(false);

    // Define the layout structure by surrounding the page content with the Header and Footer elements and contexts.
    const layoutStructure = (
        <>
            <CookiesBanner />
            <ContrastContext.Provider value={setContrast}>
                <Header contrast={contrast} />
                <PagesTransition location={location}>
                    <main>{children}</main>
                </PagesTransition>
            </ContrastContext.Provider>
            <Footer />
            <Chatbot />
        </>
    );

    // Replace the original children content with the defined layout
    // Due to the i18next plugin, the "children" element has been wrapped with the translation provider; so we need to
    // pass the children's children to our Layout structure
    const childrenWithProps = React.cloneElement(children.props.children, {}, layoutStructure);
    return React.cloneElement(children, {}, childrenWithProps);
};

Layout.propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};


export default Layout;
