import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';

const settings = require('../../../settings');


/**
 * Validate form data for null required fields.
 *
 * @param {Object} data - filled data of the form
 * @param {Object[]} schema - schema of the form fields
 * @returns {Object} - object with field errors on 'parameters' key
 */
const validateFormData = (data, schema) => {
    let hasErrors = false;
    const errors = {
        message: [<Trans>Invalid parameters</Trans>],
        fields: {},
    };

    schema.forEach(item => {
        const elementKey = item.name;
        const value = data[elementKey];
        if (!value && value !== false && item.required) {  // Validate that is not false, for boolean fields
            errors.fields[item.name] = [<Trans>This field is required.</Trans>];
            hasErrors = true;
        } else {
            item.validators?.forEach(validator => {
                const [isValid, errorMessage] = validator(value);
                if (!isValid) {
                    errors.fields[item.name] = [errorMessage];
                    hasErrors = true;
                }
            });
        }
    });

    return hasErrors ? errors : null;
};


/**
 * Prepare and send form data to Hubspot API
 *
 * @param {string} formId - the Hubspot form ID
 * @param {Object} data - data of the form
 * @returns {boolean} - success of POST to Hubspot
 */
const formPostToHubspot = async (formId, data) => {
    // Construct Hubspot Form URL
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${settings.HUBSPOT_PORTAL_ID}/${formId}`;

    // Necessary request headers
    /* eslint-disable i18next/no-literal-string */
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
    };
    /* eslint-enable i18next/no-literal-string */

    // Get some details about the current page
    const pageUri = window.location.href;
    const pageName = document.title;

    // POST the data
    const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            submittedAt: Date.now(),
            fields: Object.entries(data).map(entry => ({name: entry[0], value: entry[1]})),
            context: {pageUri, pageName},
        }),
    });

    return response.ok;
};


export {
    formPostToHubspot,
    validateFormData,
};
