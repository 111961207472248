import React from 'react';
import {Helmet} from 'gatsby-plugin-react-i18next';

const Chatbot = () => (
    <Helmet>
        <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-na1.hs-scripts.com/5194609.js"
        />

        { /* This style tag is required in order to Hubspot Chatbot not be at top of Cookie Banner */}
        <style type="text/css">
            {`
                #hubspot-messages-iframe-container {
                    z-index: 1048 !important;
                }
            `}
        </style>
    </Helmet>
);


/*
 * Exports
 */
export default Chatbot;
