/*
 * Environment
 */
const ENVIRONMENT = process.env.ENVIRONMENT || 'develop';
const STRAPI_HOST = process.env.STRAPI_HOST || 'http://localhost:1337';
const STRAPI_USER = process.env.STRAPI_USER || 'website';
const STRAPI_PASSWORD = process.env.STRAPI_PASSWORD || 'password';
const MERCHANT_API_HOST = process.env.MERCHANT_API_HOST || 'https://merchant-api.switchpayments.com';

/*
 * Constants
 */
const DEFAULT_LANGUAGE = 'en';
const EMAIL_CONTACT_URL = `${MERCHANT_API_HOST}/v1/accounts/contact`;
const GOOGLE_ANALYTICS_ID = 'UA-53998269-1';
const JOB_OFFERS_GENERAL_EMAIL = 'jobs@switchpayments.com';
const JOB_OFFERS_URL = 'https://switch-s-a.breezy.hr/json?verbose=true';
const LANGUAGES = ENVIRONMENT === 'develop' ? [DEFAULT_LANGUAGE, 'pt'] : [DEFAULT_LANGUAGE];
const LEAD_FEEDER_KEY = 'YEgkB8lqmQw7ep3Z';
const PR_TEAM_EMAIL = 'pr@switchpayments.com';
const SITE_URL = 'https://switchpayments.com';
const HUBSPOT_PORTAL_ID = 5194609;
const SALES_TEAM_EMAIL = 'sales@switchpayments.com';

/*
 * Internal
 */

// Used to map the use cases page to a use case type (so that it can be used in a query variable in a fragment without
// repeating the query for each page)
const _USE_CASE_MAPPING = {
    '/use-cases/acquirers/': 'acquirer',
    '/use-cases/isvs/': 'isv',
    '/use-cases/merchants/': 'merchant',
    '/use-cases/psps/': 'psp',
};

const BREEZY_MULTIPLE_LOCATIONS_SEPARATOR = ' or ';

/*
 * Exports
 */
module.exports = {
    BREEZY_MULTIPLE_LOCATIONS_SEPARATOR,
    DEFAULT_LANGUAGE,
    EMAIL_CONTACT_URL,
    ENVIRONMENT,
    GOOGLE_ANALYTICS_ID,
    HUBSPOT_PORTAL_ID,
    JOB_OFFERS_GENERAL_EMAIL,
    JOB_OFFERS_URL,
    LANGUAGES,
    LEAD_FEEDER_KEY,
    MERCHANT_API_HOST,
    PR_TEAM_EMAIL,
    SALES_TEAM_EMAIL,
    SITE_URL,
    STRAPI_HOST,
    STRAPI_USER,
    STRAPI_PASSWORD,

    _USE_CASE_MAPPING,
};
