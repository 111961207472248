// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-careers-index-js": () => import("./../../../src/pages/about/careers/index.js" /* webpackChunkName: "component---src-pages-about-careers-index-js" */),
  "component---src-pages-about-company-js": () => import("./../../../src/pages/about/company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-contact-sales-js": () => import("./../../../src/pages/about/contact-sales.js" /* webpackChunkName: "component---src-pages-about-contact-sales-js" */),
  "component---src-pages-about-newsroom-js": () => import("./../../../src/pages/about/newsroom.js" /* webpackChunkName: "component---src-pages-about-newsroom-js" */),
  "component---src-pages-about-thank-you-js": () => import("./../../../src/pages/about/thank-you.js" /* webpackChunkName: "component---src-pages-about-thank-you-js" */),
  "component---src-pages-channels-js": () => import("./../../../src/pages/channels.js" /* webpackChunkName: "component---src-pages-channels-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-analytics-js": () => import("./../../../src/pages/product/analytics.js" /* webpackChunkName: "component---src-pages-product-analytics-js" */),
  "component---src-pages-product-dynamic-routing-js": () => import("./../../../src/pages/product/dynamic-routing.js" /* webpackChunkName: "component---src-pages-product-dynamic-routing-js" */),
  "component---src-pages-product-processing-js": () => import("./../../../src/pages/product/processing.js" /* webpackChunkName: "component---src-pages-product-processing-js" */),
  "component---src-pages-product-reconciliation-js": () => import("./../../../src/pages/product/reconciliation.js" /* webpackChunkName: "component---src-pages-product-reconciliation-js" */),
  "component---src-pages-product-risk-js": () => import("./../../../src/pages/product/risk.js" /* webpackChunkName: "component---src-pages-product-risk-js" */),
  "component---src-pages-product-terminal-js": () => import("./../../../src/pages/product/terminal.js" /* webpackChunkName: "component---src-pages-product-terminal-js" */),
  "component---src-pages-product-vault-js": () => import("./../../../src/pages/product/vault.js" /* webpackChunkName: "component---src-pages-product-vault-js" */),
  "component---src-pages-use-cases-acquirers-js": () => import("./../../../src/pages/use-cases/acquirers.js" /* webpackChunkName: "component---src-pages-use-cases-acquirers-js" */),
  "component---src-pages-use-cases-isvs-js": () => import("./../../../src/pages/use-cases/isvs.js" /* webpackChunkName: "component---src-pages-use-cases-isvs-js" */),
  "component---src-pages-use-cases-merchants-js": () => import("./../../../src/pages/use-cases/merchants.js" /* webpackChunkName: "component---src-pages-use-cases-merchants-js" */),
  "component---src-pages-use-cases-psps-js": () => import("./../../../src/pages/use-cases/psps.js" /* webpackChunkName: "component---src-pages-use-cases-psps-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-channel-js": () => import("./../../../src/templates/channel.js" /* webpackChunkName: "component---src-templates-channel-js" */)
}

