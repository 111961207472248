import {emailValidationRegex} from './constants/common';


/*
 * Public Components
 */

/**
 * Validate if an email is correctly formatted
 *
 * @param {string} email - the email to validate
 * @returns {boolean} whether the email is valid or not
 */
const emailIsValid = email => emailValidationRegex.test(email);

/**
 * Validate if a file size is less than maximum limit
 *
 * @param {number} fileSizeLimitMb - file size limit, in MB
 * @param {object} file - the file to validate
 * @returns {boolean} whether the file size is less than the maximum limit
 */
const checkFileSizeLimit = (fileSizeLimitMb, file) => {
    // No validation problem if no file OR file size under limit
    if (!file || file.size <= fileSizeLimitMb * 1000 * 1000) {
        return true;
    }
    return false;
};

/**
 * Convert a hex color string into its RGB components
 *
 * @param {string} hex - the color to convert
 * @returns {{r: number, b: number, g: number}}
 */
const hexToRgbComponent = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
};

/**
 * Convert a hex color string into its RGBA string
 *
 * @param {string} hex - the color to convert
 * @param {number} opacity - the CSS opacity to apply to the color
 * @returns {string} the RGBA string
 */
const hexToRgba = (hex, opacity = 1) => {
    const result = hexToRgbComponent(hex);
    return `rgba(${result.r}, ${result.g}, ${result.b}, ${opacity})`;
};

/**
 * Convert an RBG component (R, G, or B) into its hex value
 *
 * @param {string} c - the RGB component to get its hex value from
 * @returns {string} the hex value for that component
 */
const rgbComponentToHex = c => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Convert an RBG color into its hex value
 *
 * @param {string} r - the Red color component
 * @param {string} g - the Green color component
 * @param {string} b - the Blue color component
 * @returns {string} the hex value for that color
 */
const rgbToHex = (r, g, b) => `#${rgbComponentToHex(r)}${rgbComponentToHex(g)}${rgbComponentToHex(b)}`;

/**
 * Scroll to the top of the window
 *
 * @returns {void}
 */
const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

/**
 * Call element.scrollIntoView but adjust scroll behaviour to prefers-reduced-motion user setting. Avoids smooth
 * scroll if true.
 * @param {Object} element - element to scroll into
 * @param {boolean} prefersReducedMotion - whether user prefers reduced motion
 * @param {Object} scrollArgs - scrollIntoView options
 */
const smoothScroll = (element, prefersReducedMotion = false, scrollArgs = {}) => {
    // eslint-disable-next-line i18next/no-literal-string
    const args = {...{behavior: prefersReducedMotion ? 'auto' : 'smooth'}, ...scrollArgs};
    element.scrollIntoView(args);
};

// Taken from: https://stackoverflow.com/a/62400741/7564579
/**
 * Filter Object keys from list
 *
 * @param {Object} obj - Object to be filtered
 * @param {string[]} allowedList - list of allowed keys
 * @returns {Object} - new Object with only the allowed keys
 */
const objectFilter = (obj, allowedList) => Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([key, _value]) => allowedList.includes(key)),
);


/*
 * Exports
 */
export {
    emailIsValid,
    checkFileSizeLimit,
    hexToRgba,
    hexToRgbComponent,
    objectFilter,
    rgbComponentToHex,
    rgbToHex,
    scrollToTop,
    smoothScroll,
};
