import {Trans} from 'gatsby-plugin-react-i18next';
import React from 'react';


// From: https://regexr.com/2rhq7
// eslint-disable-next-line max-len
const emailValidationRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

const countries = {
    afghanistan: <Trans>Afghanistan</Trans>,
    aland_islands: <Trans>Åland Islands</Trans>,
    albania: <Trans>Albania</Trans>,
    algeria: <Trans>Algeria</Trans>,
    american_samoa: <Trans>American Samoa</Trans>,
    andorra: <Trans>Andorra</Trans>,
    angola: <Trans>Angola</Trans>,
    anguilla: <Trans>Anguilla</Trans>,
    antarctica: <Trans>Antarctica</Trans>,
    antigua_and_barbuda: <Trans>Antigua and Barbuda</Trans>,
    argentina: <Trans>Argentina</Trans>,
    armenia: <Trans>Armenia</Trans>,
    aruba: <Trans>Aruba</Trans>,
    australia: <Trans>Australia</Trans>,
    austria: <Trans>Austria</Trans>,
    azerbaijan: <Trans>Azerbaijan</Trans>,
    bahamas: <Trans>Bahamas</Trans>,
    bahrain: <Trans>Bahrain</Trans>,
    bangladesh: <Trans>Bangladesh</Trans>,
    barbados: <Trans>Barbados</Trans>,
    belarus: <Trans>Belarus</Trans>,
    belgium: <Trans>Belgium</Trans>,
    belize: <Trans>Belize</Trans>,
    benin: <Trans>Benin</Trans>,
    bermuda: <Trans>Bermuda</Trans>,
    bhutan: <Trans>Bhutan</Trans>,
    plurinational_state_of_bolivia: <Trans>Plurinational State of Bolivia</Trans>,
    bonaire_sint_eustatius_and_saba: <Trans>Bonaire, Sint Eustatius and Saba</Trans>,
    bosnia_and_herzegovina: <Trans>Bosnia and Herzegovina</Trans>,
    botswana: <Trans>Botswana</Trans>,
    bouvet_island: <Trans>Bouvet Island</Trans>,
    brazil: <Trans>Brazil</Trans>,
    british_indian_ocean_territory: <Trans>British Indian Ocean Territory</Trans>,
    brunei_darussalam: <Trans>Brunei Darussalam</Trans>,
    bulgaria: <Trans>Bulgaria</Trans>,
    burkina_faso: <Trans>Burkina Faso</Trans>,
    burundi: <Trans>Burundi</Trans>,
    cambodia: <Trans>Cambodia</Trans>,
    cameroon: <Trans>Cameroon</Trans>,
    canada: <Trans>Canada</Trans>,
    cape_verde: <Trans>Cape Verde</Trans>,
    cayman_islands: <Trans>Cayman Islands</Trans>,
    central_african_republic: <Trans>Central African Republic</Trans>,
    chad: <Trans>Chad</Trans>,
    chile: <Trans>Chile</Trans>,
    china: <Trans>China</Trans>,
    christmas_island: <Trans>Christmas Island</Trans>,
    cocos_keeling_islands: <Trans>Cocos (Keeling) Islands</Trans>,
    colombia: <Trans>Colombia</Trans>,
    comoros: <Trans>Comoros</Trans>,
    congo: <Trans>Congo</Trans>,
    the_democratic_republic_of_the_congo: <Trans>The Democratic Republic of the Congo</Trans>,
    cook_islands: <Trans>Cook Islands</Trans>,
    costa_rica: <Trans>Costa Rica</Trans>,
    cote_d_ivoire: <Trans>Côte d&apos;Ivoire</Trans>,
    croatia: <Trans>Croatia</Trans>,
    cuba: <Trans>Cuba</Trans>,
    curacao: <Trans>Curaçao</Trans>,
    cyprus: <Trans>Cyprus</Trans>,
    czech_republic: <Trans>Czech Republic</Trans>,
    denmark: <Trans>Denmark</Trans>,
    djibouti: <Trans>Djibouti</Trans>,
    dominica: <Trans>Dominica</Trans>,
    dominican_republic: <Trans>Dominican Republic</Trans>,
    ecuador: <Trans>Ecuador</Trans>,
    egypt: <Trans>Egypt</Trans>,
    el_salvador: <Trans>El Salvador</Trans>,
    equatorial_guinea: <Trans>Equatorial Guinea</Trans>,
    eritrea: <Trans>Eritrea</Trans>,
    estonia: <Trans>Estonia</Trans>,
    ethiopia: <Trans>Ethiopia</Trans>,
    falkland_islands_malvinas: <Trans>Falkland Islands (Malvinas)</Trans>,
    faroe_islands: <Trans>Faroe Islands</Trans>,
    fiji: <Trans>Fiji</Trans>,
    finland: <Trans>Finland</Trans>,
    france: <Trans>France</Trans>,
    french_guiana: <Trans>French Guiana</Trans>,
    french_polynesia: <Trans>French Polynesia</Trans>,
    french_southern_territories: <Trans>French Southern Territories</Trans>,
    gabon: <Trans>Gabon</Trans>,
    gambia: <Trans>Gambia</Trans>,
    georgia: <Trans>Georgia</Trans>,
    germany: <Trans>Germany</Trans>,
    ghana: <Trans>Ghana</Trans>,
    gibraltar: <Trans>Gibraltar</Trans>,
    greece: <Trans>Greece</Trans>,
    greenland: <Trans>Greenland</Trans>,
    grenada: <Trans>Grenada</Trans>,
    guadeloupe: <Trans>Guadeloupe</Trans>,
    guam: <Trans>Guam</Trans>,
    guatemala: <Trans>Guatemala</Trans>,
    guernsey: <Trans>Guernsey</Trans>,
    guinea: <Trans>Guinea</Trans>,
    guinea_bissau: <Trans>Guinea-Bissau</Trans>,
    guyana: <Trans>Guyana</Trans>,
    haiti: <Trans>Haiti</Trans>,
    heard_island_and_mcdonald_islands: <Trans>Heard Island and McDonald Islands</Trans>,
    holy_see_vatican_city_state: <Trans>Holy See (Vatican City State)</Trans>,
    honduras: <Trans>Honduras</Trans>,
    hong_kong: <Trans>Hong Kong</Trans>,
    hungary: <Trans>Hungary</Trans>,
    iceland: <Trans>Iceland</Trans>,
    india: <Trans>India</Trans>,
    indonesia: <Trans>Indonesia</Trans>,
    islamic_republic_of_iran: <Trans>Islamic Republic of Iran</Trans>,
    iraq: <Trans>Iraq</Trans>,
    ireland: <Trans>Ireland</Trans>,
    isle_of_man: <Trans>Isle of Man</Trans>,
    israel: <Trans>Israel</Trans>,
    italy: <Trans>Italy</Trans>,
    jamaica: <Trans>Jamaica</Trans>,
    japan: <Trans>Japan</Trans>,
    jersey: <Trans>Jersey</Trans>,
    jordan: <Trans>Jordan</Trans>,
    kazakhstan: <Trans>Kazakhstan</Trans>,
    kenya: <Trans>Kenya</Trans>,
    kiribati: <Trans>Kiribati</Trans>,
    democratic_peoples_republic_of_korea: <Trans>Democratic People&apos;s Republic of Korea</Trans>,
    republic_of_korea: <Trans>Republic of Korea</Trans>,
    kuwait: <Trans>Kuwait</Trans>,
    kyrgyzstan: <Trans>Kyrgyzstan</Trans>,
    lao_peoples_democratic_republic: <Trans>Lao People&apos;s Democratic Republic</Trans>,
    latvia: <Trans>Latvia</Trans>,
    lebanon: <Trans>Lebanon</Trans>,
    lesotho: <Trans>Lesotho</Trans>,
    liberia: <Trans>Liberia</Trans>,
    libya: <Trans>Libya</Trans>,
    liechtenstein: <Trans>Liechtenstein</Trans>,
    lithuania: <Trans>Lithuania</Trans>,
    luxembourg: <Trans>Luxembourg</Trans>,
    macao: <Trans>Macao</Trans>,
    madagascar: <Trans>Madagascar</Trans>,
    malawi: <Trans>Malawi</Trans>,
    malaysia: <Trans>Malaysia</Trans>,
    maldives: <Trans>Maldives</Trans>,
    mali: <Trans>Mali</Trans>,
    malta: <Trans>Malta</Trans>,
    marshall_islands: <Trans>Marshall Islands</Trans>,
    martinique: <Trans>Martinique</Trans>,
    mauritania: <Trans>Mauritania</Trans>,
    mauritius: <Trans>Mauritius</Trans>,
    mayotte: <Trans>Mayotte</Trans>,
    mexico: <Trans>Mexico</Trans>,
    federated_states_of_micronesia: <Trans>Federated States of Micronesia</Trans>,
    republic_of_moldova: <Trans>Republic of Moldova</Trans>,
    monaco: <Trans>Monaco</Trans>,
    mongolia: <Trans>Mongolia</Trans>,
    montenegro: <Trans>Montenegro</Trans>,
    montserrat: <Trans>Montserrat</Trans>,
    morocco: <Trans>Morocco</Trans>,
    mozambique: <Trans>Mozambique</Trans>,
    myanmar: <Trans>Myanmar</Trans>,
    namibia: <Trans>Namibia</Trans>,
    nauru: <Trans>Nauru</Trans>,
    nepal: <Trans>Nepal</Trans>,
    netherlands: <Trans>Netherlands</Trans>,
    new_caledonia: <Trans>New Caledonia</Trans>,
    new_zealand: <Trans>New Zealand</Trans>,
    nicaragua: <Trans>Nicaragua</Trans>,
    niger: <Trans>Niger</Trans>,
    nigeria: <Trans>Nigeria</Trans>,
    niue: <Trans>Niue</Trans>,
    norfolk_island: <Trans>Norfolk Island</Trans>,
    north_macedonia: <Trans>North Macedonia</Trans>,
    northern_mariana_islands: <Trans>Northern Mariana Islands</Trans>,
    norway: <Trans>Norway</Trans>,
    oman: <Trans>Oman</Trans>,
    pakistan: <Trans>Pakistan</Trans>,
    palau: <Trans>Palau</Trans>,
    state_of_palestine: <Trans>State of Palestine</Trans>,
    panama: <Trans>Panama</Trans>,
    papua_new_guinea: <Trans>Papua New Guinea</Trans>,
    paraguay: <Trans>Paraguay</Trans>,
    peru: <Trans>Peru</Trans>,
    philippines: <Trans>Philippines</Trans>,
    pitcairn: <Trans>Pitcairn</Trans>,
    poland: <Trans>Poland</Trans>,
    portugal: <Trans>Portugal</Trans>,
    puerto_rico: <Trans>Puerto Rico</Trans>,
    qatar: <Trans>Qatar</Trans>,
    reunion: <Trans>Réunion</Trans>,
    romania: <Trans>Romania</Trans>,
    russian_federation: <Trans>Russian Federation</Trans>,
    rwanda: <Trans>Rwanda</Trans>,
    saint_barthelemy: <Trans>Saint Barthélemy</Trans>,
    saint_helena_ascension_and_tristan_da_cunha: <Trans>Saint Helena, Ascension and Tristan da Cunha</Trans>,
    saint_kitts_and_nevis: <Trans>Saint Kitts and Nevis</Trans>,
    saint_lucia: <Trans>Saint Lucia</Trans>,
    saint_martin_french_part: <Trans>Saint Martin (French part)</Trans>,
    saint_pierre_and_miquelon: <Trans>Saint Pierre and Miquelon</Trans>,
    saint_vincent_and_the_grenadines: <Trans>Saint Vincent and the Grenadines</Trans>,
    samoa: <Trans>Samoa</Trans>,
    san_marino: <Trans>San Marino</Trans>,
    sao_tome_and_principe: <Trans>Sao Tome and Principe</Trans>,
    saudi_arabia: <Trans>Saudi Arabia</Trans>,
    senegal: <Trans>Senegal</Trans>,
    serbia: <Trans>Serbia</Trans>,
    seychelles: <Trans>Seychelles</Trans>,
    sierra_leone: <Trans>Sierra Leone</Trans>,
    singapore: <Trans>Singapore</Trans>,
    sint_maarten_dutch_part: <Trans>Sint Maarten (Dutch part)</Trans>,
    slovakia: <Trans>Slovakia</Trans>,
    slovenia: <Trans>Slovenia</Trans>,
    solomon_islands: <Trans>Solomon Islands</Trans>,
    somalia: <Trans>Somalia</Trans>,
    south_africa: <Trans>South Africa</Trans>,
    south_georgia_and_the_south_sandwich_islands: <Trans>South Georgia and the South Sandwich Islands</Trans>,
    south_sudan: <Trans>South Sudan</Trans>,
    spain: <Trans>Spain</Trans>,
    sri_lanka: <Trans>Sri Lanka</Trans>,
    sudan: <Trans>Sudan</Trans>,
    suriname: <Trans>Suriname</Trans>,
    svalbard_and_jan_mayen: <Trans>Svalbard and Jan Mayen</Trans>,
    swaziland: <Trans>Swaziland</Trans>,
    sweden: <Trans>Sweden</Trans>,
    switzerland: <Trans>Switzerland</Trans>,
    syrian_arab_republic: <Trans>Syrian Arab Republic</Trans>,
    taiwan_province_of_china: <Trans>Taiwan, Province of China</Trans>,
    tajikistan: <Trans>Tajikistan</Trans>,
    united_republic_of_tanzania: <Trans>United Republic of Tanzania</Trans>,
    thailand: <Trans>Thailand</Trans>,
    timor_leste: <Trans>Timor-Leste</Trans>,
    togo: <Trans>Togo</Trans>,
    tokelau: <Trans>Tokelau</Trans>,
    tonga: <Trans>Tonga</Trans>,
    trinidad_and_tobago: <Trans>Trinidad and Tobago</Trans>,
    tunisia: <Trans>Tunisia</Trans>,
    turkey: <Trans>Turkey</Trans>,
    turkmenistan: <Trans>Turkmenistan</Trans>,
    turks_and_caicos_islands: <Trans>Turks and Caicos Islands</Trans>,
    tuvalu: <Trans>Tuvalu</Trans>,
    uganda: <Trans>Uganda</Trans>,
    ukraine: <Trans>Ukraine</Trans>,
    united_arab_emirates: <Trans>United Arab Emirates</Trans>,
    united_kingdom: <Trans>United Kingdom</Trans>,
    united_states: <Trans>United States</Trans>,
    united_states_minor_outlying_islands: <Trans>United States Minor Outlying Islands</Trans>,
    uruguay: <Trans>Uruguay</Trans>,
    uzbekistan: <Trans>Uzbekistan</Trans>,
    vanuatu: <Trans>Vanuatu</Trans>,
    bolivarian_republic_of_venezuela: <Trans>Bolivarian Republic of Venezuela</Trans>,
    vietnam: <Trans>Viet Nam</Trans>,
    british_virgin_islands: <Trans>British Virgin Islands</Trans>,
    us_virgin_islands: <Trans>U.S. Virgin Islands</Trans>,
    wallis_and_futuna: <Trans>Wallis and Futuna</Trans>,
    western_sahara: <Trans>Western Sahara</Trans>,
    yemen: <Trans>Yemen</Trans>,
    zambia: <Trans>Zambia</Trans>,
    zimbabwe: <Trans>Zimbabwe</Trans>,
};


export {
    emailValidationRegex,
    countries,
};
