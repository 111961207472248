import {Trans} from 'gatsby-plugin-react-i18next';
import React from 'react';
import {Input, InputFile, InputRadio, Select, TextArea} from '../../components/common/Forms';
import {checkFileSizeLimit, emailIsValid} from '../utils';

/**
 * Get the Newsletter form fields with the translated content.
 *
 * The ids of the fields are the ones expected by Hubspot form.
 *
 * @param {function} t - the translate method
 * @returns {Object[]} an array each of the form fields
 */
/* eslint-disable i18next/no-literal-string */

const getNewsletterFormSchema = t => [
    {
        name: 'email',
        label: t('E-mail'),
        required: true,
        element: Input,
        attributes: {
            type: 'email',
            placeholder: t('Enter E-mail'),
        },
        validators: [
            value => ([emailIsValid(value), <Trans>Email is invalid.</Trans>]),
        ],
    },
];

const newsletterHubspotFormId = 'e9eedd18-6a9c-4951-a337-17c2c532d0fb';
/* eslint-enable i18next/no-literal-string */


/**
 * Get the Blog Newslist form fields with the translated content.
 *
 * The ids of the fields are the ones expected by Hubspot form.
 *
 * @param {function} t - the translate method
 * @returns {Object[]} an array each of the form fields
 */
/* eslint-disable i18next/no-literal-string */
const getBlogNewslistFormSchema = t => [
    {
        name: 'name',
        label: t('Name'),
        required: true,
        element: Input,
        attributes: {
            type: 'text',
            placeholder: t('Name'),
        },
    },
    {
        name: 'email',
        label: t('E-mail'),
        required: true,
        element: Input,
        attributes: {
            type: 'email',
            placeholder: t('E-mail'),
        },
        validators: [
            value => ([emailIsValid(value), <Trans>Email is invalid.</Trans>]),
        ],
    },
];

const newslistHubspotFormId = '46ea6b8a-1026-41f8-94d8-d2a48c6297fa';
/* eslint-enable i18next/no-literal-string */


/**
 * Get the Contact Us form fields with the translated content.
 *
 * The ids of the fields are the ones expected by Hubspot form.
 *
 * @param {function} t - the translate method
 * @returns {Object[]} an array each of the form fields
 */
/* eslint-disable i18next/no-literal-string */
const getContactUsFormSchema = t => [
    {
        name: 'firstname',
        label: t('First Name'),
        required: true,
        element: Input,
        attributes: {
            type: 'text',
            placeholder: t('Enter First Name'),
        },
    },
    {
        name: 'lastname',
        label: t('Last Name'),
        required: true,
        element: Input,
        attributes: {
            type: 'text',
            placeholder: t('Enter Last Name'),
        },
    },
    {
        name: 'email',
        label: t('E-mail'),
        required: true,
        element: Input,
        attributes: {
            type: 'email',
            placeholder: t('Enter E-mail'),
        },
        validators: [
            value => ([emailIsValid(value), <Trans>Email is invalid.</Trans>]),
        ],
    },
    {
        name: 'message',
        label: t('Message'),
        required: true,
        element: TextArea,
        attributes: {
            placeholder: t('Enter your message'),
        },
    },
];

const contactUsHubspotFormId = '27937a15-9247-4ba3-9731-4302a7bf7480';
/* eslint-enable i18next/no-literal-string */


/**
 * Get the Contact Sales form fields with the translated content.
 *
 * The ids of the fields are the ones expected by Hubspot form.
 *
 * @param {function} t - the translate method
 * @returns {Object[]} an array each of the form fields
 */
/* eslint-disable i18next/no-literal-string */
const getContactSalesFormSchema = t => [
    {
        name: 'name',
        label: t('Name'),
        required: true,
        element: Input,
        attributes: {
            type: 'text',
            placeholder: t('Enter your Name'),
        },
    },
    {
        name: 'email',
        label: t('Work e-mail'),
        required: true,
        element: Input,
        attributes: {
            type: 'email',
            placeholder: t('Enter your corporate e-mail'),
        },
        validators: [
            value => ([emailIsValid(value), <Trans>Email is invalid.</Trans>]),
        ],
    },
    {
        name: 'average_monthly_payments_volume',
        label: t('Average monthly payments volume'),
        required: true,
        element: Select,
        getValue: event => (event.value),
        attributes: {
            type: 'select',
            placeholder: t('Select average monthly payments volume'),
            options: [
                {
                    value: '< $50.000',
                    label: t('Less than $50.000'),
                },
                {
                    value: '$50.000 to $100.000',
                    label: t('$50.000 to $100.000'),
                },
                {
                    value: '$100.000 to $500.000',
                    label: t('$100.000 to $500.000'),
                },
                {
                    value: '$500.000 to $1.000.000',
                    label: t('$500.000 to $1.000.000'),
                },
                {
                    value: '$1.000.000 to $10.000.000',
                    label: t('$1.000.000 to $10.000.000'),
                },
                {
                    value: '> $10.000.000',
                    label: t('More than $10.000.000'),
                },
            ],
        },
    },
    {
        name: 'average_monthly_number_of_transactions',
        label: t('Average monthly number of transactions'),
        required: true,
        element: Select,
        getValue: event => (event.value),
        attributes: {
            type: 'select',
            placeholder: t('Select average monthly number of transactions'),
            options: [
                {
                    value: '< 25.000',
                    label: t('Less than 25.000'),
                },
                {
                    value: '25.000 to 50.000',
                    label: t('25.000 to 50.000'),
                },
                {
                    value: '50.000 to 100.000',
                    label: t('50.000 to 100.000'),
                },
                {
                    value: '100.000 to 250.000',
                    label: t('100.000 to 250.000'),
                },
                {
                    value: '> 250.000',
                    label: t('More than 250.000'),
                },
            ],
        },
    },
    {
        name: 'message',
        label: t('Message'),
        required: true,
        element: TextArea,
        attributes: {
            placeholder: t('Enter your message'),
        },
    },
];

const contactSalesHubspotFormId = '770402a1-1520-4c77-89cc-bd5abbe8fc74';
/* eslint-enable i18next/no-literal-string */


/**
 * Get the Submit CV form with the translated content.
 *
 * @param {function} t - the translate method
 * @returns {Object[]} an array each of the form fields
 */
/* eslint-disable i18next/no-literal-string */
const getSubmitCVFormSchema = t => [
    {
        name: 'firstName',
        label: t('First Name'),
        required: true,
        element: Input,
        attributes: {
            type: 'text',
            placeholder: t('Enter First Name'),
        },
    },
    {
        name: 'lastName',
        label: t('Last Name'),
        required: true,
        element: Input,
        attributes: {
            type: 'text',
            placeholder: t('Enter Last Name'),
        },
    },
    {
        name: 'email',
        label: t('E-mail'),
        required: true,
        element: Input,
        attributes: {
            type: 'email',
            placeholder: t('Enter E-mail'),
        },
        validators: [
            value => ([emailIsValid(value), <Trans>Email is invalid.</Trans>]),
        ],
    },
    {
        name: 'phone',
        label: t('Phone'),
        required: true,
        element: Input,
        attributes: {
            type: 'tel',
            placeholder: t('Enter Phone'),
        },
    },
    {
        name: 'resume',
        label: t('Resume/CV'),
        required: true,
        element: InputFile,
        getValue: event => (event.target.files && event.target.files.length ? event.target.files[0] : null),
        attributes: {
            placeholder: t('Attachment (.pdf)'),
            acceptedFileExtensions: '.pdf',
        },
        validators: [
            file => ([checkFileSizeLimit(10, file), <Trans>File size must be less than 10 MB.</Trans>]),
        ],
    },
    {
        name: 'coverLetter',
        label: t('Cover Letter'),
        required: false,
        element: InputFile,
        getValue: event => (event.target.files && event.target.files.length ? event.target.files[0] : null),
        attributes: {
            placeholder: t('Attachment (.pdf)'),
            acceptedFileExtensions: '.pdf',
        },
        validators: [
            file => ([checkFileSizeLimit(10, file), <Trans>File size must be less than 10 MB.</Trans>]),
        ],
    },
    {
        name: 'reason',
        label: t('Why do you want to join Switch?'),
        required: true,
        element: TextArea,
        attributes: {
            placeholder: t('Enter message'),
        },
    },
    {
        name: 'fit',
        label: t('Why do you think you are a good fit for this position?'),
        required: true,
        element: TextArea,
        attributes: {
            placeholder: t('Enter message'),
        },
    },
    {
        name: 'url',
        label: t('Any URL you want to share?'),
        required: false,
        element: Input,
        attributes: {
            type: 'url',
            placeholder: t('Enter link'),
        },
    },
    {
        name: 'origin',
        label: t('Where did you get to know Switch?'),
        required: true,
        element: Input,
        attributes: {
            type: 'url',
            placeholder: t('Event, Social Media, Direct Message, Friends & Family, Press, Other.'),
        },
    },
    {
        name: 'authorization',
        label: t('Are you authorized to work in this location?'),
        required: true,
        element: InputRadio,
        getValue: event => (event.target.value === 'true'),
        attributes: {
            options: [
                {
                    name: (
                        <Trans>
                            <strong>Yes</strong>
                            , I’m authorized to work in this location
                        </Trans>
                    ),
                    value: true,
                },
                {
                    name: (
                        <Trans>
                            <strong>No</strong>
                            , I&apos;ll require an employer&apos;s sponsorship to obtain work authorization
                        </Trans>
                    ),
                    value: false,
                },
            ],
        },
    },
];
/* eslint-enable i18next/no-literal-string */


export {
    contactSalesHubspotFormId,
    contactUsHubspotFormId,
    newsletterHubspotFormId,
    newslistHubspotFormId,
    getSubmitCVFormSchema,
    getContactSalesFormSchema,
    getContactUsFormSchema,
    getBlogNewslistFormSchema,
    getNewsletterFormSchema,
};
