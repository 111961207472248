import React from 'react';


/**
 * Send a custom event to Google Analytics.
 *
 * @param {String} category - Event category
 * @param {String} action - Event action
 * @param {String} label - Event label
 */
const sendGoogleAnalyticsEvent = (category, action, label) => {
    /* eslint-disable-next-line i18next/no-literal-string */
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
    });
};

export default sendGoogleAnalyticsEvent;
