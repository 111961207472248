import React from 'react';
import {TransitionGroup, Transition as ReactTransition} from 'react-transition-group';
import PropTypes from 'prop-types';

/*
 * Private Elements
 */

const pageTransitionDurationMs = 350;
const pageTransitionStyles = {
    entering: {
        opacity: 1,
        // eslint-disable-next-line i18next/no-literal-string
        position: 'absolute',
        width: '100%',
    },
    entered: {
        opacity: 1,
        transition: `opacity ${pageTransitionDurationMs}ms ease-in-out`,
    },
    exiting: {
        opacity: 0,
        transition: `opacity ${pageTransitionDurationMs}ms ease-in-out`,
    },
};


/*
 * Public Elements
 */

/**
 * Transitions between pages
 *
 * Wraps page content with a div that changes styles (opacity) when the location changes.
 *
 * @param location - window.location
 * @param children - element to wrap
 */
const PagesTransition = ({location, children}) => (
    <TransitionGroup>
        <ReactTransition
            key={location.pathname}
            timeout={{enter: pageTransitionDurationMs, exit: pageTransitionDurationMs}}
        >
            {status => (
                <div className="animation" style={{...pageTransitionStyles[status]}}>
                    {children}
                </div>
            )}
        </ReactTransition>
    </TransitionGroup>
);

PagesTransition.propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

export {
    PagesTransition,
};
