/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
import {ContextProvider} from './src/helpers/context';

// Pass some Context Providers (e.g. ScreenSizeContext) that don't need to render on page changes (hence the Root)
const wrapRootElement = ({element}) => <ContextProvider>{element}</ContextProvider>;

const onRouteUpdate = ({location}) => {
    /*
     * Change page and scroll into element
     *
     * Use Gatsby link/navigate and pass 'scrollToId' in state. E.g.: state: {scrollToId: 'elementId'}
     */
    // Check location.state for ID of an element to scroll into view; if any
    const elementIdToScroll = location.state?.scrollToId;
    if (elementIdToScroll) {
        // Scroll element into view
        const element = document.getElementById(elementIdToScroll);
        if (element) {
            element.scrollIntoView({});
        }
    }
};


export {
    wrapRootElement,
    onRouteUpdate,
};
