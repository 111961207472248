import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';


const ScreenSizeContext = React.createContext(null);

const ContrastContext = React.createContext(null);

const ContextProvider = ({children}) => {
    // Lower order components may need to know the screen size. We get the screen size and pass down that information
    // through a context provider.
    const initialState = {width: 0, height: 0, widthWithoutScrollbar: 0};
    const [screenSize, setScreenSize] = useState(initialState);
    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
                widthWithoutScrollbar: document.documentElement.clientWidth,
            });
        };

        // Add an event listener to handle when the page is resized
        window.addEventListener('orientationchange', handleResize, {passive: true});
        window.addEventListener('resize', handleResize, {passive: true});

        // If the current screen size is the SAME object as the initial one (reference comparison), set the initial size
        // immediately when the page renders
        if (screenSize === initialState) {
            handleResize();
        }

        return () => {
            // Clean up the event handler when the component unmounts
            window.removeEventListener('orientationchange', handleResize);
            window.removeEventListener('resize', handleResize);
        };
    });

    return (
        <ScreenSizeContext.Provider value={screenSize}>
            {children}
        </ScreenSizeContext.Provider>
    );
};

ContextProvider.propTypes = {
    children: PropTypes.object.isRequired,
};

export {
    ScreenSizeContext,
    ContrastContext,
    ContextProvider,
};
