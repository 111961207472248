import React from 'react';

import styled from '@emotion/styled';
import {Trans} from 'gatsby-plugin-react-i18next';

import {CTALink, P2} from './Typography';
import {breakpoints, colors, fontWeights} from '../../styles/theme';
import useLocalStorage from '../../helpers/hooks/useLocalStorage';


/*
 * Public Elements
 */
const StyledCookiesBanner = styled.div`
    position: fixed;
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: 25px;
    right: 25px;
    padding: 15px;
    border-radius: 5px;
    background-color: ${colors.black};
    color: ${colors.mediumGrey};
    z-index: 1050;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.24);

    ${P2} {
        margin: 0;
    }

    .selection {
        ${CTALink} {
            font-weight: ${fontWeights.semiLight};
            padding: 6px;

            &.agree {
                margin-left: -6px;
                margin-right: 8px;
                font-weight: ${fontWeights.semiBold};
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        max-width: 400px;
        bottom: 35px;
        right: 35px;
    }
`;

const CookiesBanner = () => {
    // eslint-disable-next-line i18next/no-literal-string
    const [accepted, setAccepted] = useLocalStorage('cookies-accepted', false);

    // Do not even render if it's already accepted
    if (accepted) {
        return null;
    }

    const acceptCookies = () => setAccepted(true);
    return (
        <StyledCookiesBanner>
            <P2>
                <Trans>
                    We use cookies to improve our services and your experience.
                    By using our website, you consent to cookies.
                </Trans>
            </P2>
            <div className="selection">
                <CTALink onClick={acceptCookies} className="agree"><Trans>Dismiss</Trans></CTALink>
                <CTALink to="/privacy-policy"><Trans>Learn more</Trans></CTALink>
            </div>
        </StyledCookiesBanner>
    );
};


/*
 * Exports
 */
export default CookiesBanner;
