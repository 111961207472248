import {useEffect, useRef} from 'react';


/**
 * Detect a click event outside an element, and execute a callback
 *
 * @param {function} callback - the callback to execute when an outside click is found
 * @returns {Object} the reference to apply to the desired element
 */
export default callback => {
    const ref = useRef(null);

    useEffect(() => {
        // Check that the click happened outside of the element, and execute the callback
        const handleClick = event => { if (!ref.current?.contains(event.target)) callback(event); };

        document.addEventListener('click', handleClick, {passive: true});

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });

    return ref;
};
