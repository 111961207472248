import React, {useEffect, useState} from 'react';


/**
 * Hook to let the application know if it is being rendered on the client or on the server.
 *
 * @returns {boolean} - whether it's on the client (true) or not
 */
export default () => {
    const [isClient, setIsClient] = useState(false);
    useEffect(() => setIsClient(true), []);
    return isClient;
};
