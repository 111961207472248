import React from 'react';
import PropTypes from 'prop-types';

import useIsClient from '../../helpers/hooks/useIsClient';


/*
 * Public Elements
 */
/**
 * Render a component only on the client side.
 * https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 *
 * @returns {node} - The component to be rendered in the client side, or null otherwise
 */
const ClientOnly = ({children}) => (useIsClient() ? children : null);

ClientOnly.propTypes = {
    children: PropTypes.node.isRequired,
};


/*
 * Exports
 */
export default ClientOnly;
